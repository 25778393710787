import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { filter, map, sortBy, uniqBy } from 'lodash';
import MultiSelect from '../../MultiSelect/MultiSelect';
import { ssuRoles } from '../../../constants';

/* eslint-disable react/prop-types */
export default function UserRoleSitesMultiselectField({ field, form, ssus }) {
  const { values } = form;
  const { selectedStudyId, role } = values;
  const disabled = !selectedStudyId || !ssuRoles.includes(role);

  const dataSource = useMemo(() => {
    if (disabled) {
      return [];
    }
    return sortBy(
      map(
        uniqBy(
          filter(ssus, ssu => {
            return ssu.status !== 'Canceled' && ssu.studyId === selectedStudyId;
          }),
          'siteId'
        ),
        ({ siteName, siteId }) => ({
          name: siteName,
          id: siteId,
          size: 'Medium'
        })
      ),
      ({ name }) => name.toUpperCase()
    );
  }, [disabled, selectedStudyId, ssus]);

  return (
    <MultiSelect
      value={field.value}
      useNewStyle
      label="Sites"
      placeholder="Select Sites"
      disabled={disabled}
      dataSource={dataSource}
      onChange={selected => {
        if (selected && selected.length > 0) {
          form.setFieldValue('selectedSiteId', selected[0].id);
          form.setFieldValue('selectedSiteIds', map(selected, ({ id }) => id));
        } else {
          form.setFieldValue('selectedSiteId', '');
          form.setFieldValue('selectedSiteIds', []);
        }
      }}
      searchable
      clearSearchOnSelection
    />
  );
}
/* eslint-enabled react/prop-types */

UserRoleSitesMultiselectField.prototype = {
  field: PropTypes.any,
  form: PropTypes.any,
  ssus: PropTypes.object
};
