/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useMemo } from 'react';
import cx from 'classnames';
import { isEmpty, isEqual, map, sortBy, reject, size } from 'lodash';

import SingleSelect from '../gizmos/SingleSelect';
import './MultiSelect.scss';

function defaultMultipleSelectedValueTemplateFunction(elements) {
  return (
    <React.Fragment>
      {elements
        .map(el => el && el.name)
        .filter(el => el && el.id !== 'ALL')
        .join(', ')}
      <span className="count">{elements.length}</span>
    </React.Fragment>
  );
}

function withoutSelectAll(options) {
  if (isEmpty(options)) {
    return [];
  }
  return options.filter(({ id }) => id !== 'SELECT_ALL');
}

export default function MultiSelect({
  customClass,
  value,
  dataSource,
  onChange,
  ...rest
}) {
  const source = useMemo(
    () =>
      size(dataSource) < 2
        ? dataSource
        : [
            {
              id: 'SELECT_ALL',
              name: 'Select All',
              selectAllGroup: 'selectAllGroup'
            },
            ...dataSource
          ],
    [dataSource]
  );

  const {
    sortedValue,
    allOptionsSelected,
    someOptionsSelected
  } = useMemo(() => {
    const plainValue = reject(
      map(value, id => dataSource.find(site => id === site.id)),
      isEmpty
    );
    const sortedValue = sortBy(plainValue, 'name');
    const sortedDataSource = sortBy(dataSource, 'name');
    const allOptionsSelected = isEqual(sortedDataSource, sortedValue);
    const someOptionsSelected = !allOptionsSelected && !isEmpty(sortedValue);
    return {
      sortedValue,
      sortedDataSource,
      allOptionsSelected,
      someOptionsSelected
    };
  }, [dataSource, value]);

  function onChangeWrapper(options) {
    const sortedOptions = sortBy(options, 'name');

    if (isEqual(sortedOptions, sortedValue)) {
      if (size(sortedValue) !== size(value)) {
        onChange([]);
      }
      return;
    }

    const a = withoutSelectAll(sortedOptions);
    const b = withoutSelectAll(sortedValue);

    const isSelectAllClicked = isEqual(a, b);

    let nextSelected = [];

    if (isSelectAllClicked) {
      if (!allOptionsSelected) {
        nextSelected = dataSource;
      }
    } else {
      nextSelected = a;
    }

    onChange(nextSelected);
  }

  function customOptionTemplateFunction(el) {
    return (
      <>
        <i
          className={cx('checkbox-icon', {
            'checkbox-icon-indeterminate':
              el.id === 'SELECT_ALL' && someOptionsSelected,
            'checkbox-icon-checked':
              el.id === 'SELECT_ALL' && allOptionsSelected
          })}
        ></i>
        <span>{el.name}</span>
      </>
    );
  }

  return (
    <SingleSelect
      customClass={cx(customClass, 'multiple-select-container')}
      initialValue={sortedValue}
      dataSource={source}
      onChange={onChangeWrapper}
      {...rest}
      multiple
      customSelectedValueTemplateFunction={
        defaultMultipleSelectedValueTemplateFunction
      }
      customOptionTemplateFunction={customOptionTemplateFunction}
      groupBy="selectAllGroup"
      clearSelectedValueOnDataSourceChange
    />
  );
}

/* eslint-enable react/prop-types */
/* eslint-enable no-shadow */

MultiSelect.defaultProps = {
  deselectOnSelectedOptionClick: true,
  keepOpenOnSelection: true,
  focusToSelectedValue: false,
  closeOnSelectedOptionClick: false
};
