import React from 'react';
import { roleNames } from '../../constants';

const commonColumns = [
  {
    Header: 'Role',
    accessor: 'roleName',
    Cell: ({ value }) => (value && roleNames[value]) || '',
    minWidth: 150
  },
  {
    Header: 'Study',
    accessor: 'studyName',
    minWidth: 150
  },
  {
    Header: 'Site',
    accessor: 'siteName',
    minWidth: 120
  },
  {
    Header: 'Start Date',
    accessor: 'momentStartDate', // sort by this
    Cell: ({ original }) => original.startDate, // but display this
    width: 190
  },
  {
    Header: 'Expire Date',
    accessor: 'momentExpireDate', // sort by this
    Cell: ({ original }) => original.expireDate, // but display this
    width: 190
  },
  {
    Header: 'Group',
    /* eslint-disable react/prop-types */
    Cell: ({ original }) => {
      const groupAssignName =
        original && original.groupAssign ? original.groupAssign.name : null;
      const blindingGroup = original.blindingGroup
        ? original.blindingGroup
        : null;
      return (
        <div style={{ whiteSpace: 'break-spaces' }}>
          {groupAssignName || blindingGroup || ''}
        </div>
      );
    },
    /* eslint-enable react/prop-types */
    width: 125
  },
  {
    Header: 'Patient Payment Access',
    accessor: 'financeDetailsAccess',
    Cell: ({ original }) => {
      if (original.roleName === 'stuman') {
        return original.financeDetailsAccess === 'false' ? 'No' : 'Yes';
      }
      return '';
    },
    width: 150
  }
];
export default commonColumns;
